<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Master from "@/apis/Master";
import {required,numeric} from "vuelidate/lib/validators";
import Vue from "vue";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "B2B USERS",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "B2B USERS",
          active: true
        }
      ],
      checkbox:[],
      isCheckAll:false,
      userData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100, 125, 150, 200, 225, 250],
      filter: null,
      filterOn: [],
      sortBy: "userId",
      sortDesc: true,
      selected:"true",
      fields: [
        { key: "checkbox", sortable: false, label: "" },
        { key: "firstName", sortable: true, label: "USER NAME",  thStyle: { color: "black", "font-size":"16px"}  },
        // { key: "lastName", sortable: true, label: "LAST NAME",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "userEmail", sortable: true, label: "USER DETAILS",  thStyle: { color: "black", "font-size":"16px"}  },
        // { key: "mobile", sortable: true, label: "MOBILE NO",  thStyle: { color: "black", "font-size":"16px"}  },
        // { key: "address1", sortable: true, label: "ADDRESS",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "discountPercentange", sortable: true, label: "PRICE DISCOUNT",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "business_type", sortable: true, label: "ACCOUNT TYPE",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "verificationStatus", sortable: true, label: "STATUS",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "action", label: "ACTION",  thStyle: { color: "black", "font-size":"16px"}  }
      ],
      showRejectModel: false,
      submittedReject: false,
      priceDiscount:{
        userId : "",
        discountPercentage: ""
      },

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.userData.length;
    }
  },
  validations: {
    priceDiscount:{
      discountPercentage: {required, numeric}
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.userData.length;

    Master.userList().then((res) => {
      console.log(res.data.data.userId)
      this.userData = res.data.data;
    })
  },

  methods: {
    /**
     * Edit Country Modal
     */
    checkAll: function(){
      this.isCheckAll = !this.isCheckAll;
      this.checkbox = [];
      if(this.isCheckAll){ // Check all
        for (var key in this.userData) {
          this.checkbox.push(this.userData[key].userId);
        }
      }
    },

    rejectModal(data,discount){
      this.showRejectModel = true;
      this.priceDiscount.userId = data;
      this.priceDiscount.discountPercentage = discount
    },
    hideRejectModel(){
      this.showRejectModel = false;
      this.submittedReject = false;
      this.priceDiscount.discountPercentage= ""
    },

    updateDiscountPercentage(){
      Master.updateDiscountPercentage(
              {
                userId: this.priceDiscount.userId,
                discountPercentage:this.priceDiscount.discountPercentage
              }).then(res => {
        console.log(res);

        Master.userList().then((res) => {
          console.log(res.data.data.userId)
          this.userData = res.data.data;
        })


        Vue.swal({
          position: "center",
          icon: "success",
          title: ""+res.data.message+"",
          showConfirmButton: false,
          timer: 1500
        });
        // this.successMessage = res.data.message;
        this.showRejectModel = false
        this.submittedReject = false

      })


    },
    handleRejectedSubmit(){
      this.submittedReject = true;
      this.$v.$touch();
      if (this.$v.priceDiscount.$invalid) {
        return;
      } else {
        this.updateDiscountPercentage();
      }
    },

    async bulkApprove(){
      // if (this.checkbox[0] == null){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.approveUsers({
          ids: this.checkbox,
          verificationStatus: 1
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.userList().then((res) => {
            console.log(res)
            this.userData = res.data.data;
          })
        })
      }
    },
    async bulkActive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.updateUserStatus({
          ids: this.checkbox,
          userStatus: 1
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.userList().then((res) => {
            console.log(res)
            this.userData = res.data.data;
          })
        })
      }
    },
    async bulkInactive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.updateUserStatus({
          ids: this.checkbox,
          userStatus: 0
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.userList().then((res) => {
            console.log(res)
            this.userData = res.data.data;
          })
        })
      }
    },
    async bulkDelete(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        if (confirm('Are you sure you want to delete this item?')) {
          Master.userBulkDelete({
            ids: this.checkbox,
            trash: 1
          }).then((res) => {
            // this.successMessage = res.data.message;
            Vue.swal({
              position: "center",
              icon: "success",
              title: "<p style='color: red'>"+res.data.message+"</p>",
              showConfirmButton: false,
              timer: 1500
            });
            this.checkbox = []
            this.isCheckAll = false
            Master.userList().then((res) => {
              console.log(res)
              this.userData = res.data.data;
            })
          })
        }
      }
    },
    async bulkDisApprove(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.approveUsers({
          ids: this.checkbox,
          verificationStatus: 2
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.userList().then((res) => {
            console.log(res)
            this.userData = res.data.data;
          })
        })
      }
    },

    userDetails(data){
      localStorage.setItem("userId",data);
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform: uppercase !important;">
            <div class="btn-toolbar">
              <a href="javascript:void(0);" class="btn btn-outline-info mb-2 mr-1" @click="bulkApprove">
                <i class="mdi mdi-account-check mr-2"></i> Approve
              </a>
              <a href="javascript:void(0);" class="btn btn-outline-danger mb-2 mr-1" @click="bulkDisApprove">
                <i class="mdi mdi-account-cancel mr-2"></i> Reject
              </a>

              <a href="javascript:void(0);" class="btn btn-success mb-2 mr-1" @click="bulkActive">
                <i class="mdi mdi-account-check mr-2"></i> Active
              </a>
              <a href="javascript:void(0);" class="btn btn-pink mb-2 mr-1" @click="bulkInactive">
                <i class="mdi mdi-account-cancel mr-2"></i> In Active
              </a>
              <a href="javascript:void(0);" class="btn btn-danger mb-2 mr-1" @click="bulkDelete">
                <i class="mdi mdi-trash-can mr-2"></i> Delete
              </a>
            </div>

            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered"
                    :items="userData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    striped
                    sort-icon-left
                >
                  <template v-slot:head(checkbox)>
                    <div class="form-check mb-1">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="isCheckAll"
                          :id="`customercheck`"
                          @click='checkAll()'
                      />
                      <label class="form-check-label" :for="`customercheck`"></label>
                    </div>
                  </template>



                  <template v-slot:cell(firstName)="row">
                    <router-link to="/user-details" :data-date="row.item.userId"><p class="badge font-size-12"  @click="userDetails(row.item.userId)">
                      {{row.item.firstName}} {{row.item.lastName}}
                    </p></router-link>
                  </template>
<!--                  <template v-slot:cell(lastName)="row">-->
<!--                    <p class="badge font-size-12">-->
<!--                      -->
<!--                    </p>-->
<!--                  </template>-->

<!--                  <template v-slot:cell(mobile)="row">-->
<!--                    <p class="badge font-size-12">-->
<!--                      {{row.item.mobile}}-->
<!--                    </p>-->
<!--                  </template>-->
                  <template v-slot:cell(address1)="row">
                    <p class="badge font-size-12">
                      {{row.item.address1}}
                    </p>

                  </template>
                  <template v-slot:cell(discountPercentange)="row">
                    <p class="badge font-size-12">
                      {{row.item.discountPercentange}} %
                    </p>

                  </template>
<!--                  <template v-slot:cell(countryName)="row">-->
<!--                    <p class="badge font-size-12">-->
<!--                      {{row.item.countryName}}-->
<!--                    </p>-->
<!--                  </template>-->



                  <template v-slot:cell(verificationStatus)="row">
                    <p v-if="row.value == 1"

                    >
                      <span v-if="row.item.userStatus == 1"
                            class="badge font-size-12 badge-soft-success"
                      >ACTIVE </span>
                    <span v-else class="badge font-size-12 badge-soft-danger"> IN ACTIVE </span>
                    </p>

                    <div v-else-if="row.value == 2"
                         class="badge font-size-12 badge-soft-danger"
                    >REJECTED </div>
                    <p v-else
                         class="badge font-size-12 badge-soft-warning"
                    >WAITING FOR APPROVAL</p>
                  </template>

                  <template v-slot:cell(business_type)="row">
                    <p v-if="row.item.businessType == 'INDIVIDUAL'"
                         class="badge font-size-12"
                    >INDIVIDUAL</p>
                    <p  v-else
                         class="badge font-size-12"
                    >BUSINESS</p><br/>
                    <p class="badge font-size-12">
                      {{row.item.countryName}}
                    </p>
                  </template>
                  <template v-slot:cell(userEmail)="row">
                    <ul class="pl-1" style="list-style-type: none;">
                      <li>
                         <div class="badge font-size-12">
                          {{row.item.userEmail}}
                        </div>
                      </li>
                      <li>
                        <div class="badge font-size-12">
                          +{{row.item.mobile}}
                        </div>
                      </li>
                    </ul>

                  </template>
                  <template v-slot:cell(checkbox)="row">
                    <div class="form-check mb-3">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="checkbox"
                          :id="`customercheck${row.index}`"
                          :value="row.item.userId"
                      />
                      <label class="form-check-label" :for="`customercheck${row.index}`">&nbsp;</label>
                    </div>
                  </template>
                  <template v-slot:cell(action) = "data">
                    <router-link to="/user-details" :data-date="data.item.userId"><a
                        href="javascript:void(0);"
                        class="mr-3 text-warning"
                        @click="userDetails(data.item.userId)"
                        v-b-tooltip.hover
                        title="VIEW"
                    >
                      <i class="mdi mdi-eye font-size-18"></i>
                    </a></router-link>

                    <a
                            href="javascript:void(0);"
                            class="mr-3 text-primary"
                            @click="rejectModal(data.item.userId, data.item.discountPercentange)"
                            v-b-tooltip.hover
                            title="UPDATE DISCOUNT PRICE"
                    >
                      <img src="../../../assets/images/price_icon.png" height="29px" width="29px">
<!--                      <i class="mdi mdi-update font-size-18"></i>-->
                    </a>


                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal
            id="modal-1"
            v-model="showRejectModel"
            title="UPDATE PRICE DISCOUNT PERCENTAGE"
            title-class="text-dark font-18"
            hide-footer
            @close="hideRejectModel"
    >

      <form @submit.prevent="handleRejectedSubmit">
        <div class="form-group">
          <label for="name">DISCOUNT PERCENTAGE *</label>
          <input
                  id="percentage"
                  v-model="priceDiscount.discountPercentage"
                  type="text"
                  class="form-control"
                  placeholder="ENTER IN PERCENTAGE"
                  :class="{ 'is-invalid': submittedReject   && $v.priceDiscount.discountPercentage.$error  }"
                  maxlength="2"

          />

          <div v-if="submittedReject && $v.priceDiscount.discountPercentage.$error" class="invalid-feedback">
            <span v-if="!$v.priceDiscount.discountPercentage.required">DISCOUNT PERCENTAGE IS REQUIRED</span>
            <span v-if="!$v.priceDiscount.discountPercentage.numeric">DISCOUNT PERCENTAGE SHOULD BE IN NUMERIC</span>
          </div>

        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideRejectModel">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>